import React from "react";
import Homepage from "./pages/Homepage";
import "./App.scss";

function App() {
  return (
    <Homepage />
  );
}

export default App;
